import React from "react";
import * as Images from "../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../redux/slices/auth";

const AdminNav = () => {

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const auth =  useSelector((state)=>state.auth)

  const userName = auth.userInfo.name;
  const profilePhoto = "https://icon-library.com/images/no-user-image-icon/no-user-image-icon-21.jpg";

  const userLogout = () => {
    dispatch(logout({
        cb(res) {
          navigate("/admin/login")
        }
    }))
  };

  return (
    <>
      <section className="main">
        <div className="sidebar">
          <div className="sidebarLogo">
            <Link to="/admin/dashboard">
              <img src={Images.logo} alt="LeftIcon" className="logo" />
            </Link>
          </div>
          <div className="sidebar_menu">
            <ul className="nav_main">
            <Link to="/admin/dashboard" className="nav_link">
            <li className={`nav_items ${location.pathname.includes('/admin/dashboard') ? 'active':  ''}`}>
            <i className="las la-tachometer-alt navIcon me-3"></i>
                      Dashboard
                </li>
                </Link>
              <Link to="/admin/users/0" className="nav_link">
             
              <li className={`nav_items ${location.pathname.includes('/admin/users') ? 'active':  ''}`}>
                      {/* <i className="fas fa-users navIcon  "></i> */}
                      <i className="las la-user-friends navIcon me-3"></i>
                      Users
                </li>
              </Link>
            </ul>
          </div>
        </div>
      </section>
      <div className="commonrightBoxNav">
        <div className="navMain">
          <div className="container">
            <div className="nav_box">
              <div className="main_logo">
              </div>
              <div className="user_details">
                <img
                  src={Images.Welcome}
                  alt="LeftIcon"
                  className="welcomeIcon"
                />
                <span className="userName">Hey, Welcome {userName ?? 'Aegis'}</span>
                <div className="dropdown">
                  <span
                    className="dropdown-toggle userDetails"
                    type="button"
                    id="userDropdown"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    {userName ?? 'Aegis'}
                  </span>
                  <ul
                    className="dropdown-menu userMenu"
                    aria-labelledby="userDropdown"
                  >
                    {/* <li className="user_menudetails">
                      <Link className="user-item" to="/viewedit">
                        <img
                          src={Images.EditIcon}
                          alt="edit"
                          className="dropdownIcon"
                        />{" "}
                        View & Edit Profile
                      </Link>
                    </li> */}
                    
                    <li className="user_menudetails">
                      <Link to="/admin/users/changePassword" className="user-item">
                        <img
                          src={Images.Lock}
                          alt="edit"
                          className="dropdownIcon"
                        />
                        Change Password
                      </Link>
                    </li>
                    <li
                      className="user_menudetails"
                    >
                      <Link  to="/admin/users/updateProfile" className="user-item">
                        <img
                          src={Images.Profile}
                          alt="edit"
                          className="dropdownIcon"
                        />
                        Update Profile
                      </Link>
                    </li>
                    <li
                      onClick={() => {
                        userLogout();
                      }}
                      className="user_menudetails"
                    >
                      <Link className="user-item">
                        <img
                          src={Images.Logout}
                          alt="edit"
                          className="dropdownIcon"
                        />
                        Log Out
                      </Link>
                    </li>
                  </ul>
                </div>
                <img src={profilePhoto} alt="LeftIcon" className="userIcon" />
              </div>
            </div>
          </div>
        </div>
        {/* <div className="navMain mobileMain">
            <div className="container">
                <div className="nav_box">
                    <div className="main_logo">
                    <Link to="/dashboard"><img src={Images.logo} alt='LeftIcon' className="logo" /></Link>
                    <div className="">
                    <img src={Images.Welcome} alt='LeftIcon' className="welcomeIcon" />
                        <span className="userName">Hey, Welcome {userName}</span>
                    </div>
                    </div>
                    <div className="user_details">
                        
                        <div className="dropdown">
                            <span className="dropdown-toggle userDetails" type="button" id="userDropdown" data-bs-toggle="dropdown" aria-expanded="false">
                            {userName}
                            </span>
                            <ul className="dropdown-menu userMenu" aria-labelledby="userDropdown">
                                <li className="user_menudetails"><Link className="user-item" to="/viewedit"><img src={Images.EditIcon} alt="edit" className="dropdownIcon" /> View &  Edit Profile</Link></li>
                                <li   onClick={() => { userLogout() }} className="user_menudetails"><a className="user-item" href="#"><img src={Images.Logout} alt="edit" className="dropdownIcon" />Log Out</a></li>
                            </ul>
                        </div>
                        <img src={Images.User} alt='LeftIcon' className="userIcon" />
                    </div>
                </div>
            </div>
        </div>  */}
      </div>
    </>
  );
};

export default AdminNav;
