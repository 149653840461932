import React, { useState } from 'react';
import * as Images from "../../utilities/images";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OtpInput from 'react-otp-input';
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { codeVerify, forgotPassword } from '../../redux/slices/auth';

const OtpVerification = () => {

    const [otp, setOtp] = useState('');
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const  email  = localStorage.getItem("email")

    const handleChangeOtp = (value) => {
        setOtp(value);
    }

    const resendOTP = (e) => {
        e.preventDefault();
        let params = {
            email: email,
        }

        dispatch(forgotPassword ({
            ...params, cb(res) {

            }
        }))
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!otp) {
            toast.error("Please enter OTP");
            return;
        }
        if(!/^[0-9]{6,6}$/.test(otp)){
            toast.error("OTP must be numeric and 6 digit long");
            return;
        }

        let params = {
            email: email,
            // code: parseInt(otp),
            code: otp
        }

        dispatch(codeVerify({
            ...params, cb(res) {
                if (res.status == 200) {
                    navigate('/admin/recoverPassword')
                }
                else {
                    // toast.error("invalid otp")
                }
            }
        }))
    }


    return (
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4">
                        <div className="login_details">
                            <h1 className="login_ mb-2">Enter OTP</h1>
                            <p className="welcome_note mb-4">Enter the OTP that we just sent your on your
                                email address.</p>
                            <form onSubmit={(e) => handleSubmit(e)} id="form">
                                <div className='otp_box'>
                                    <OtpInput
                                        containerStyle={{justifyContent:'space-evenly'}}
                                        value={otp}
                                        onChange={handleChangeOtp}
                                        numInputs={6}
                                        renderInput={(props) => <input {...props} className='enterOtp' />}
                                    />
                                </div>
                                <p className='resend_otp mt-5 text-center'>I didn't received code...<span className='resend' onClick={(e) => { resendOTP(e) }}> Resend OTP</span> </p>

                                <button className="submit_btn w-100 my-3" type="submit" onClick={(e) => {
                                     handleSubmit(e) 
                                     }}>
                                    Submit
                                </button>
                                <p className="forget_password text-center"><Link to='/admin/login'>
                                    <img src={Images.backIcon} alt='LeftIcon' className="backIcon" />Back to Login</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <p className="copyright mobileCopyright ">© Copyright {new Date().getFullYear()} all rights reserved. Aegis 24/7</p>
                </div>
            </div>
        </>
    )
}

export default OtpVerification