import React from 'react';
import {Navigate, Outlet, useNavigate } from 'react-router-dom';

const AdminLayout = () => {

  const navigate = useNavigate()
  const token = localStorage.getItem('authToken')

  return (
    <>    
        <main className='logBox' id='logBox'>
          <Outlet />
        </main>
    </>
  );

};

export default AdminLayout;