import axios from "axios";
import { toast } from "react-toastify";
import BaseUrl from "../Constants/baseUrl";

const axiosInstance = axios.create({
    baseURL: BaseUrl.API_URL,
    headers: {
        Accept: "application/json"
    }
});

//axios.defaults.headers.common['Authorization'] = localStorage.getItem('token') 

// Set the AUTH token for any request
axiosInstance.interceptors.request.use(function (config) {
    const token = localStorage.getItem('authToken');
    config.headers.Authorization = token ? token : '';
    return config;
});


// Add a response interceptor
axiosInstance.interceptors.response.use(
    (response) => {
      // Handle successful responses
      return response;
    },
    (error) => {
      // Handle response errors
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('authToken');
        localStorage.removeItem('refreshToken');
        localStorage.removeItem('persist:root');
        window.location.reload()
        toast.warning('Session expired, please login again')
      }
      return Promise.reject(error);
    }
  );
  



const axiosGet = (url, params = {}) => {

    return axiosInstance.get(url, {params:params})
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
};


const axiosPut = (url, params = {}) => {

    return axiosInstance.put(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
};

const axiosPost = (url, params = {}) => {

    return axiosInstance.post(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
};

const axiosPatch = (url, params = {}) => {

    return axiosInstance.patch(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
}

const postDelete = (url, params = {}) => {
    return axiosInstance.delete(url, params)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
}

const axiosPostFormData = (url, params) => {
    if(params.document){
        var formData = new FormData();
        formData.append('document', params?.document);
    }
    else{
        console.log(params, "paramsparamsparams");
        var formData = new FormData();
        formData.append('profile_image', params?.profile_image);
    }
    return axiosInstance.post(url, formData)
        .then(response => {
            return { status: response.status, data: response.data }
        })
        .catch(err => {
            throw err
        });
};


export const ApiClient = {
    get: axiosGet,
    put: axiosPut,
    post: axiosPost,
    patch: axiosPatch,
    delete: postDelete,
    postFormData: axiosPostFormData,
    // deleteWithData:deleteWithData
}

