import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import { onErrorStopLoad, setChangePassword, setCodeVerify, setForgotPassword, setLogout, setResetPassword, setUpdateProfile, setUserLogin } from '../../slices/auth';
import ApiPath from '../../../Constants/apiPath';
import { toast } from 'react-toastify';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userLogin(action) {

  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_LOGIN_USER, action.params = action.payload);
  
    if (resp.status == 200) {
      localStorage.setItem("authToken", resp.data.data.access_token ? resp.data.data.access_token : "")
      localStorage.setItem("refreshToken", resp.data.data.refresh_token ? resp.data.data.refresh_token : "")

      yield put(setUserLogin(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setUserLogin({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* userLogout(action) {
  
  try {

    const resp = yield call(ApiClient.get, action.url = ApiPath.AuthApiPath.ADMIN_LOGOUT_USER, action.params = {});
    

    if (resp.status == 200) {

      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('persist:root');

      yield call(action.payload.cb, action.res = resp)

      yield put(setLogout())  

      toast.success(resp.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* forgotpassword(action) {

  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_FORGOT_PASSWORD, action.params = action.payload);
    if (resp.status == 200) {

      yield put(setForgotPassword());
      yield call(action.payload.cb, action.res = resp)
      localStorage.setItem("email", action.payload.email)

      toast.success(resp.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* codeVerify(action) {

  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_VERIFY, action.params = action.payload);
  
    if (resp.status == 200) {

      localStorage.setItem("authToken", resp.data.data.access_token ? resp.data.data.access_token : "")
      localStorage.setItem("refreshToken", resp.data.data.refresh_token ? resp.data.data.refresh_token : "")
      
      yield put(setCodeVerify(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      toast.success(resp.data.msg);

    }
    else {
      throw resp
    }
  } catch (e) {

    toast.error('Invalid OTP');
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* resetPassword(action) {

  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_RESET_PASSWORD, action.params = action.payload);
  

    if (resp.status == 200) {

      localStorage.removeItem('authToken');
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('persist:root');

      yield put(setResetPassword());
      yield call(action.payload.cb, action.res = resp)
      toast.success(resp.data.msg);

    }
    else {
      throw resp
    }
  } catch (e) {

    console.log(e,'e-from-api');
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* changePassword(action) {

  const paramToSend = {...action.payload}
  delete paramToSend.cb

  try {
    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_CHANGE_PASSWORD, action.params = paramToSend);
  

    if (resp.status == 200) {

      yield put(setChangePassword());
      yield call(action.payload.cb, action.res = resp)
      toast.success(resp.data.msg);

    }
    else {
      throw resp
    }
  } catch (e) {

    console.log(e,'e-from-api');

    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* updateProfile(action) {

  const paramToSend = {...action.payload}
  delete paramToSend.cb

  try {

    const resp = yield call(ApiClient.post, action.url = ApiPath.AuthApiPath.ADMIN_UPDATE_PROFILE, action.params = paramToSend);

    console.log(resp,'resonse-from-api');

    if (resp.status == 200) {

      yield put(setUpdateProfile(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      toast.success(resp.data.msg);

    }
    else {
      throw resp
    }
  } catch (e) {

    console.log(e,'e-from-api');

    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}




function* authSaga() {
  yield all([
    takeLatest('auth/userLogin', userLogin),
    takeLatest('auth/logout', userLogout),
    takeLatest('auth/forgotPassword', forgotpassword),
    takeLatest('auth/codeVerify', codeVerify),
    takeLatest('auth/resetPassword', resetPassword),
    takeLatest('auth/changePasswordAction', changePassword),
    takeLatest('auth/updateProfileAction', updateProfile)

    
  ])
}

export default authSaga;