import React,{useState} from "react";
import * as Images from "../../utilities/images";
// import { recoverPass } from '../../redux/slices/auth';
import { Link, useNavigate, useLocation } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';
import { resetPassword } from "../../redux/slices/auth";



const RecoverPassword = () => {
    const[recoverPassword,setRecoverPassword]=useState({
        password:"",
        confirmPass:"",
    });
    const [errorMessages, setErrorMessages] = useState({});
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();

    const [passwordShown, setPasswordShown] = useState(false);
    const [passwordConfirmShown, setConfirmPasswordShown] = useState(false);


    const handleChange = (e) => {
        const { value, name } = e.target;
        setRecoverPassword({ ...recoverPassword, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    const handleSubmit = (e) => {
        e.preventDefault();

        if (!recoverPassword.password) {
            toast.error("Please enter password");
            return;
        }

        if (!recoverPassword.confirmPass) {
            toast.error("Please enter confirm password");
            return;
        }

        if (recoverPassword.password != recoverPassword.confirmPass) {
            toast.error("Password and confirm password must be same")
            return;
        }


        if (recoverPassword.password.length <= 7) {
            toast.error("Password must be alteast 8 character")
            return;
        }

        
        if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(recoverPassword.password)){
            toast.error("Password must be atleast 1 number , 1 capital letter and a special character");
            return
        }
            
        
        let params = {
            newPassword: recoverPassword.password,
            confirmPassword: recoverPassword.confirmPass,
        }
        
        dispatch(resetPassword({
            ...params, cb(res) {
                if (res.status == 200) {
                    navigate("/admin/login")
                    // toast.success("Password recovered succesfully!");
                }
                else {
                    toast.error("Something went wrong");
                }
            }
        }))
    }
    //   //password field show or hide
    const togglePasswordVisiblity = () => {
        setPasswordShown(passwordShown ? false : true);
    }
    const toggleConfirmPasswordVisiblity = () => {
        setConfirmPasswordShown(passwordConfirmShown ? false : true);
    }

    return(
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4">
                    <div className="login_details">
                            <h1 className="login_">Recover Password</h1>
                            <p className="welcome_note mb-4">Create your new password here.</p>
                            <form id="form">
                                <div className="form-group mb-4">
                                    <label className="input_label" for="uname">Password</label>
                                    <input type={passwordShown ? "text" : "password"} placeholder="Enter Password" name="password" value={recoverPassword.password} onChange={(e) => handleChange(e)} className="form-control input_box " />
                                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                                    <span className="toggle_password_ info_icon" onClick={() => { togglePasswordVisiblity(!passwordShown) }}>
                                        <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                    </span>
                                        {/* <img src={Images.eyeIcon} alt='LeftIcon' className="eyeIcon" /> */}
                                </div>
                                <div className="form-group mb-4">
                                    <label className="input_label" for="uname">Confirm Password</label>
                                    <input type={passwordConfirmShown ? "text" : "password"} placeholder="Enter Password" name="confirmPass" value={recoverPassword.confirmPass} onChange={(e) => handleChange(e)} className="form-control input_box " />
                                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                                    <span className="toggle_password_ info_icon" onClick={() => { toggleConfirmPasswordVisiblity(!passwordConfirmShown) }}>
                                        <span className={passwordConfirmShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                    </span>
                                    {/* <img src={Images.eyeIcon} alt='LeftIcon' className="eyeIcon" /> */}
                                </div>
                                <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                <artical className="correct_password mb-2">
                                <figure>
                                <img src={Images.Correct} alt='LeftIcon' className="correctIcon" />

                                    </figure>
                                    <p>Must be at least 8 Characters!</p>
                                </artical>
                                <artical className="incorrect_password mb-4">
                                    <figure>
                                        <img src={Images.Wrong} alt='LeftIcon' className="incorrectIcon" />
                                    </figure>
                                    <p>Must be at least 1 Number & 1 Capital Case later!</p>
                                </artical>

                                <button className="submit_btn w-100" type="submit" onClick={(e) => {
                                    handleSubmit(e)
                                }}><span className="submit_text">Recovery</span></button>
                                <p className="forget_password text-center mt-3"><Link to='/admin/login'>
                                    <img src={Images.backIcon} alt='LeftIcon' className="backIcon" />Go back to login</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <p className="copyright mobileCopyright ">© Copyright {new Date().getFullYear()} all rights reserved. Aegis 24/7</p>
                </div>
            </div>
        </>
    )

}

export default RecoverPassword;