import React from 'react';
import AgesICon from '../Assets/Images/logo-vertical.svg'

const LocationPermission = () => {
    return (
        <div className='location-permission'>
            <img src={AgesICon} alt="" className='mb-5' />
            <div className='title mb-3 mx-2'>
                Map access unavailable
            </div>
            <div className='sub-title mb-3 mx-2'>
                In Settings, enable Location Services.
            </div>
            <div className='sub-title mb-3 mx-2'>
                Allow location access for your default web browser.
            </div>
        </div>
    );
};

export default LocationPermission;