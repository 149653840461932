import { all, call, put, takeLatest } from 'redux-saga/effects'
import { ApiClient } from '../../../utilities/api';
import ApiPath from '../../../Constants/apiPath';
import { setUserList, onErrorStopLoad, setCountList, setDeleteUser, setlogOutUser, setSuspendUser } from '../../slices/users'
import { toast } from 'react-toastify';

// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* userList(action) {

  const paramToSend = {...action.payload}
  delete paramToSend.cb

  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.userApiPath.USER_LISTING}`,action.params = paramToSend);

    if (resp.status == 200) {
    //   localStorage.setItem("authToken", resp.data.data.access_token ? resp.data.data.access_token : "")
    //   localStorage.setItem("refreshToken", resp.data.data.refresh_token ? resp.data.data.refresh_token : "")

      yield put(setUserList(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setUserList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}

function* allUserList(action) {

  // ?page=${action.payload.page}&limit=${action.payload.limit}
  const paramToSend = {...action.payload}
  delete paramToSend.cb

  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.userApiPath.USER_LISTING_ALL}`,action.params = paramToSend);

    if (resp.status == 200) {
    //   localStorage.setItem("authToken", resp.data.data.access_token ? resp.data.data.access_token : "")
    //   localStorage.setItem("refreshToken", resp.data.data.refresh_token ? resp.data.data.refresh_token : "")

      yield put(setUserList(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setUserList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


// Worker saga will be fired on USER_FETCH_REQUESTED actions
function* countList(action) {


  const paramToSend = {...action.payload}
  delete paramToSend.cb

  try {
    const resp = yield call(ApiClient.get, action.url = ApiPath.userApiPath.USER_COUNT_LISTING, action.params = paramToSend);
  
    if (resp.status == 200) {
      yield put(setCountList(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      // toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setCountList({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* suspendUser(action) {

  const paramToSend = {...action.payload}
  delete paramToSend.id
  delete paramToSend.cb

  try {

    const resp = yield call(ApiClient.post, action.url = `${ApiPath.userApiPath.USER_SUSPEND}${action.payload.id}`, action.params = paramToSend);
  
    if (resp.status == 200) {
      yield put(setSuspendUser());
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setSuspendUser());
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}




function* deleteUser(action) {
  console.log("action",action)

  try {
    const resp = yield call(ApiClient.put, action.url = `${ApiPath.userApiPath.USER_DELETE}${action.payload.id}`, action.payload = action.payload);
  
    if (resp.status == 200) {
      yield put(setDeleteUser(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setDeleteUser({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}


function* logOutUser(action) {
  console.log("action",action)

  try {
    const resp = yield call(ApiClient.get, action.url = `${ApiPath.userApiPath.USER_LOGOUT}${action.payload.id}`, action.payload = action.payload);
  
    if (resp.status == 200) {
      yield put(setlogOutUser(resp.data.data));
      yield call(action.payload.cb, action.res = resp)
      toast.success(action.res.data.msg);
    }
    else {
      throw resp
    }
  } catch (e) {

    yield put(setlogOutUser({}));
    yield put(onErrorStopLoad())
    toast.error(e.response.data.msg);
  }
}






function* userSaga() {
  yield all([
    takeLatest('user/userList', userList),
    takeLatest('user/allUserList', allUserList),
    takeLatest('user/countList', countList),
    takeLatest('user/deleteUser', deleteUser),
    takeLatest('user/logOutUser', logOutUser), 
    takeLatest('user/suspendUser', suspendUser), 
    
  ])
}

export default userSaga;