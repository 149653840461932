const ApiPath = {

    AuthApiPath: {
        ADMIN_LOGIN_USER: '/api/v1/admin/login',
        ADMIN_LOGOUT_USER: '/api/v1/admin/logout',
        ADMIN_REFRESH_TOKEN: '/api/v1/admin/refresh-token',
        ADMIN_FORGOT_PASSWORD: '/api/v1/admin/forgot-password',
        ADMIN_RESET_PASSWORD: '/api/v1/admin/reset-password',
        ADMIN_CHANGE_PASSWORD: '/api/v1/admin/change-password',
        ADMIN_UPDATE_PROFILE: '/api/v1/admin/update-profile',
        ADMIN_VERIFY: '/api/v1/admin/code-verify',
    },
    userApiPath: {
        USER_LISTING: '/api/v1/admin/users-listing',
        USER_LISTING_ALL: '/api/v1/admin/users-listing-all',
        USER_COUNT_LISTING: "/api/v1/admin/dashboard",
        USER_DELETE: '/api/v1/admin/delete-user/',
        USER_LOGOUT: '/api/v1/admin/admin-logout-user/',
        USER_SUSPEND: '/api/v1/admin/suspend-user/'
    }
    
}

export default ApiPath;
