// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
        apiKey: "AIzaSyC7bw4uHNHCVPyCLoR9xifdFa6rMOdgoII",
        authDomain: "hip-wharf-305912.firebaseapp.com",
        projectId: "hip-wharf-305912",
        storageBucket: "hip-wharf-305912.appspot.com",
        messagingSenderId: "927932050759",
        appId: "1:927932050759:web:02a30eb85381f3ac113062",
        measurementId: "G-DGXN23BZWF"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export { db }