import React, { useEffect, useState } from "react";
import { Link } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { countList } from "../../../redux/slices/users";


const Dashboard = () => {

    const dispatch = useDispatch();
    const [countLists, setCountLists] = useState([]);
    console.log("userListes", countLists);

    const getCountList = () => {
        dispatch(countList({
            cb(res) {
                if (res?.status == 200) {
                    console.log(res, 'userCount')
                    setCountLists(res?.data?.data)
                }
                else {

                }
            }
        }))
    };


    useEffect(() => {
        getCountList()
    }, [])


    return(
        <>
        <div className="row mb-5">
        <h1>Dashboard</h1>
        </div>
        <div className="row dash-cards">
           
            <div className="col-md-4">
                <Link to="/admin/users/0">
                    <div className="card text-center">
                        <div className="card-body">
                        <h5 className="card-title">Total Users</h5>
                        <p className="card-text">
                            <i className="fas fa-users"></i> <span className="user-count">{countLists?.totalUsersCount}</span>
                        </p>
                        </div>
                    </div>
                </Link>
            </div>
            {/* <div className="col-md-4">
            <div className="card text-center">
                <div className="card-body">
                <h5 className="card-title">Active Users</h5>
                <p className="card-text">
                    <i className="fas fa-user-check"></i> <span className="user-count">750</span>
                </p>
                </div>
            </div>
            </div> */}
            <div className="col-md-4">
                <Link to="/admin/users/1">
                    <div className="card text-center">
                        <div className="card-body">
                        <h5 className="card-title">Current Users</h5>
                        <p className="card-text">
                            <i className="fas fa-user-plus"></i> <span className="user-count">{countLists?.newUsersCount}</span>
                        </p>
                        </div>
                    </div>
                </Link>
            </div>
        </div>
        </>
    )

}

export default Dashboard;