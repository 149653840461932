import React,{useState}from 'react';
import * as Images from "../../utilities/images";
import { forgotPass, forgotPassword } from '../../redux/slices/auth';
import { Link, useNavigate,useLocation  } from "react-router-dom";
import { useDispatch } from 'react-redux'
import { toast } from 'react-toastify';


const ForgotPassword = () => {
    const [useremail,setEmail]=useState("");
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation();
    //let user_id = location?.state?.id;
    //form submit
    const handleSubmit = (e) => {
        e.preventDefault();
    
        if (!useremail) {
            toast.error("Please enter email address");
            return;
        }
        else if (useremail && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(useremail)) {
            toast.error("Please enter valid email address");
            return;
        }

        let params = {
            email:useremail,
            
        }
        
        dispatch(forgotPassword({
            ...params, cb(res) {
                if (res.status == 200) {   
                    navigate('/admin/otpVerification');
                }
                else {
                    navigate("/admin/login")
                }
            }
        }))
    }
  

    return (
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4">
                        <div className="login_details">
                            <h1 className="login_ mb-2">Forgot Password</h1>
                            <p className="welcome_note mb-4">Enter the email address that associated with your
                                account.</p>
                            <form id="form">
                                <div className="form-group mb-4">
                                    <label className="input_label " htmlFor="uname">Email</label>
                                    <input type="email" placeholder="Email Address" name="email" value={useremail} onChange={(e)=>setEmail
                                    (e.target.value)} className="form-control input_box " />
                                    <img src={Images.Message} alt='LeftIcon' className="inputIcon" />
                                </div>
                                <button className="submit_btn w-100 my-3" type="submit" onClick={(e) => { 
                                    handleSubmit(e) 
                                }}
                                >
                                Continue
                                </button>
                                <p className="forget_password text-center"><Link to='/admin'>
                                <img src={Images.backIcon} alt='LeftIcon' className="backIcon" />Back to Login</Link></p>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="row text-center">
                    <p className="copyright mobileCopyright ">© Copyright {new Date().getFullYear()} all rights reserved. Aegis 24/7</p>
                </div>
            </div>
        </>
    )
}

export default ForgotPassword