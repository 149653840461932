import Login from "../Components/Admin/login"
import ForgotPassword from "../Components/Admin/forgotPassword"
import OtpVerification from "../Components/Admin/otpVerification"
import RecoverPassword from "../Components/Admin/recoverPassword"
import Dashboard from "../Components/Admin/Dashboard"
import Users from "../Components/Admin/Users"
import ChangePassword from "../Components/Admin/Users/changePassword"
import UpdateProfile from "../Components/Admin/Users/updateProfile"

export {
    Login,
    ForgotPassword,
    OtpVerification,
    RecoverPassword,
    Dashboard,
    Users,
    ChangePassword,
    UpdateProfile,
}