import { Button } from "bootstrap";
import React, { useEffect, useState } from "react";
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import { userList , deleteUser, logOutUser, suspendUser, allUserList} from "../../../redux/slices/users";
import ReactPaginate from "react-paginate";
import CsvDownloadButton from 'react-json-to-csv'; 
import { useParams } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert'; 

const Users = () => {
    const dispatch = useDispatch();

    let { currentUserStatus:currentUser } = useParams();
    let limit = 15;

    const [userLists, setUserLists] = useState([]);

    const [allUserListData, setAllUserListData] = useState([]);

    const [pageCount, setPageCount] = useState(1);
    const [currentPage, setCurrentPage] = useState(1);

    const [search, setSearch] = useState('');
    const [filter, setFilter] = useState('desc');
    const [activeFilter, setActiveFilter] = useState(2);
    
    const handlePageClick = (e) => {
        setCurrentPage(e.selected + 1)
        getUserList(e.selected + 1,limit,search,filter,activeFilter,currentUser);
    };

    const handleActiveFilter = (data) => {
        setActiveFilter(data)
        getUserList(1,limit,search,filter,data,currentUser)
    };
    
    const handleFilter = (fil) => {
        setFilter(fil)
        getUserList(1,limit,search,fil,activeFilter,currentUser)
    };
  
    const getUserList = (page = 1 , limit = limit , search = search , sort = filter , status = 2, currentUsers = 0) => {
        let params = {
            page: page,
            limit: limit,
            search: search,
            sort: sort,
            status: status,
            currentUsers : currentUsers
        }
        dispatch(userList({
            ...params, cb(res) {
                if (res?.status == 200) {
                    setUserLists(res?.data?.data?.result);
                    setPageCount(res?.data?.data?.totalPages)
                }
                else {

                }
            }
        }))
    };

    const getAllUserList = (currentUsers = currentUser) => {
        let params = {
            // search: search,
            // sort: sort,
            // status: status,
            currentUsers : currentUsers
        }
        dispatch(allUserList({
            ...params, cb(res) {
                if (res?.status == 200) {
                    setAllUserListData(res?.data?.data);
                }
                else {
                }
            }
        }))
    };
      
    const suspendUserCall = (id = null,status = 0) => {

        confirmAlert({
            title: 'Please Confirm',
            message: `Are you sure to ${status == 0 ? 'activate' : 'de-activate' } this user ?`,
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let params = {
                            id: id,
                            status: status
                        }
                        dispatch(suspendUser({
                            ...params, cb(res) {

                                console.log(res)

                                if (res?.status == 200) {
                                    getUserList(currentPage,limit,search,filter,activeFilter,currentUser)
                                }
                                else {
                                }
                            }
                        }))
                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false
                    }
                }
            ]
        });
    };

    const handleDelete = (id) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure to delete this user ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {

                        let params = {
                            id: id
                        }
                        dispatch(deleteUser({
                            ...params, cb(res) {
                                 if (res?.status == 200) {
                                     getUserList(currentPage,limit,search,filter,activeFilter,currentUser)
                                     // setUserLists(res?.data?.data?.result)
                                 }
                                 else {
                                 }
                             }
                         }))

                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false
                    }
                }
            ]
        });
            
    }

    const handleLogout = (id) => {

        confirmAlert({
            title: 'Please Confirm',
            message: 'Are you sure to make this user log-out from current active device ?',
            buttons: [
                {
                    label: 'Yes',
                    onClick: () => {
                        let params = {
                            id: id
                        }
                        dispatch(logOutUser({
                        ...params, cb(res) {
                                if (res?.status == 200) {
                                    getUserList(currentPage,limit,search,filter,activeFilter,currentUser)
                                    // setUserLists(res?.data?.data?.result)
                                }
                                else {
                                }
                            }
                        }))


                    }
                },
                {
                    label: 'No',
                    onClick: () => {
                        return false
                    }
                }
            ]
        });
    }

    useEffect(() => {
        if(currentUser){
            getUserList(1,limit,null,'desc',2,currentUser)
            getAllUserList(currentUser)
        }
    }, [currentUser])

    return (
        <>
        <div className="headingPage">
                <h1>{currentUser == 1 ?'Current':'All' } Users</h1>
                <div className="searchFilterBox">
                <div className="mx-3">
                <i className="las la-search searchIcon"></i>
                <input type='search' name='search' value={search} className="searchBox" placeholder="search..." onChange={(e)=>{
                    setSearch(e.target.value)
                    getUserList(1,10,e.target.value,filter,activeFilter,currentUser)
                }}/>
                </div>
                <div className="dropdown">
                    <button className="btn dropdown-toggle filterBox" type="button" id="filter" data-bs-toggle="dropdown" aria-expanded="false">
                        {filter == 'aesc' ? 'Ascending' : 'Descending'}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="filter">
                        <li onClick={()=> {handleFilter('aesc')}} ><a className="dropdown-item"> Ascending </a></li>
                        <li onClick={()=> {handleFilter('desc')}}> <a className="dropdown-item"> Descending </a></li>
                    </ul>
                </div>
                <div className="dropdown">
                    <button className="btn dropdown-toggle filterBox" type="button" id="filter" data-bs-toggle="dropdown" aria-expanded="false">
                        {activeFilter == 2 ? 'All' :  ( activeFilter == 1 ? 'Non-Active' : 'Active' )}
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="filter">
                        <li onClick={()=> {handleActiveFilter(2)}} ><a className="dropdown-item"> All </a></li>
                        <li onClick={()=> {handleActiveFilter(0)}} ><a className="dropdown-item"> Active </a></li>
                        <li onClick={()=> {handleActiveFilter(1)}}> <a className="dropdown-item"> Non-Active </a></li>
                    </ul>
                </div>
                <div className="donload-csv">
                    <CsvDownloadButton className="logoutButton" 
                        data={
                            allUserListData.map((innerData) => {
                                return {
                                    Name: innerData.name ?? '--',
                                    Email: innerData.email ?? '--',
                                    'Country Code': innerData.country_code ?? '--',
                                    'Phone Number': innerData.phone_number ?? '--',
                                    'SMS Verified': innerData.sms_verified == true ? 'Yes' : 'No',
                                    'Email Verified': innerData.email_verified == 0 ? 'No' : 'Yes',
                                    'Active Status' : innerData.is_suspended == 0 ? 'Activated' : 'De-activated'
                                }
                            })
                        }                        
                        >
                        Download CSV
                    </CsvDownloadButton>
                </div>
                </div>
            </div>
            {userLists?.length > 0 ?
                <div className="table-responsive CustomerBox mt-3">
                    <table id="tableCustomer" className="table_Customer">
                        <thead>
                            <tr className="userTableHeading">
                                <th className="headingCustomer">Name</th>
                                <th className="headingCustomer">Email</th>
                                <th className="headingCustomer">Phone Number</th>
                                <th className="headingCustomer">SMS Verified</th>
                                <th className="headingCustomer">Email Verified</th>
                                <th className="headingCustomer">Action</th>
                            </tr>
                        </thead>
                        <tbody>
                            {userLists?.map((data, index) => {
                                return (
                                    <tr key={index} className="activities_user ">
                                        <td className="subheadingCustomer">
                                            {data?.name}
                                        </td>
                                        <td className="subheadingCustomer">
                                            {data?.email ?? '- -'}
                                        </td>
                                        <td className="subheadingCustomer">
                                            {data?.country_code + '-' + data?.phone_number}
                                        </td>

                                        <td className="subheadingCustomer">
                                            {data?.sms_verified == true ?'Yes':'No'}
                                        </td>

                                        <td className="subheadingCustomer">
                                            {data?.email_verified == 0 ?'No':'Yes'}
                                        </td>
                                        
                                        <td className="subheadingCustomer">
                                            <button className="deleteButton" onClick={() => handleDelete(data?.id)}>Delete</button>

                                            <button className="deactivate mx-3" onClick={() => suspendUserCall(data?.id,data.is_suspended == 0 ? 1 : 0)} >
                                            {data.is_suspended == 0
                                            ?'De-activate':'Activate'} 
                                            </button>

                                            {data.device_token != null ? 
                                                <button className="logoutButton" onClick={() => handleLogout(data?.id)}>Log Out</button>
                                            : "" }
                                        </td>
                                    </tr>
                                );
                            })
                            }
                        </tbody>
                    </table>
                </div>
                : "No Users Found"}
             <div className="col-12 ">
                <div className="customPagination">
                    <div className="pagination">
                        {userLists?.length > 0 ? (
                            
                            <ReactPaginate
                                breakLabel=""
                                className="paginationBox"
                                // nextLabel=">"
                                nextLabel={<i className='fas fa-caret-right paginationIcon'></i>}
                                onPageChange={handlePageClick}
                                pageRangeDisplayed={pageCount}
                                marginPagesDisplayed={0}
                                pageCount={pageCount}
                                previousLabel={<i className='fas fa-caret-left paginationIcon'></i>}
                                // previousLabel="<"
                                renderOnZeroPageCount={null}
                            />
                        ) : ""}
                    </div>
                </div>
            </div>
        </>
    )

}

export default Users;