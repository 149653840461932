import React, { useEffect, useRef, useState } from 'react';
import { db } from '../Firebase'
import { doc, onSnapshot } from "firebase/firestore";
import { getAuth, signInAnonymously } from "firebase/auth"
import mapboxgl, { Marker } from "mapbox-gl";
import ReactDOM from 'react-dom/client';
import LocationStopped from './LocationStopped';
import Aegis from '../Assets/Images/logo.svg';
import HelpIcon from '../Assets/Images/help-icon.svg'
import ShareIcon from '../Assets/Images/share.svg'
import copyIcon from '../Assets/Images/copy.svg'
import MarkerIcon from '../Assets/Images/destination-pin.svg';
import FlagIcon from '../Assets/Images/destination-location.svg';
import DownIcon from '../Assets/Images/chevron-down.svg'
import LocationPermission from './LocationPermissionDenied';
import { toast } from 'react-toastify';
import moment from 'moment';


// const URL = "https://prodapi.aegis-247.com/api/v1";
// const URL = "http://localhost:5000/api/v1";
// const URL = "https://api.aegis.itechnolabs.tech/api/v1";


const URL = "https://api.aegis-247.com/api/v1";


const FIREBASE_TABLE = "user_production";
// const FIREBASE_TABLE = "user_development";

const MAPBOX_TOKEN = "pk.eyJ1IjoiYWVnaXMyNDciLCJhIjoiY2wyMDZtYjE4MG5rbDNkb2FtZmQ0aHBnZiJ9.4M4mUhHq1PYcEPrXIUqXQw"

const Location = () => {

    const [loading , setLoading] = useState(true);
    const [location , setLocation] = useState(null);
    const [comment, setComment] = useState(null);

    const queryParams = new URLSearchParams(window.location.search)

    const [currentUserLocation, setCurrentUserLocation] = useState(null)
    const [destinationUserLocation, setDestinationUserLocation] = useState(null)
    const [destinationUserData, setDestinationUserData] = useState(null)
    const [MAP, setMAP] = useState(null)
    const [destinationMarker, setDestinationMarker] = useState(null)
    const [isLocationShared, setIsLocationShared] = useState(true)
    const mapContainerRef = useRef(null);
    const [geoLocationController, setGeoLocationController] = useState(
        new mapboxgl.GeolocateControl({
            positionOptions: {
                enableHighAccuracy: true
            },
            trackUserLocation: true,
            showUserHeading: true,
            compact:true
        })
    )
    const [visible, setVisible] = useState(false)
    const [locationPermissionGranted, setLocationPermissionGranted] = useState(true)
    const [checkInType, setCheckInType] = useState(false)
    const [isMidPointMarker, setIsMidPointMarker] = useState(false)

    const toggleVisible = () => {
        const scrolled = document.documentElement.scrollTop;
        if (scrolled > 0) {
            setVisible(false)
        } else if (destinationMarker.getPopup().isOpen() === false && scrolled === 0) {
            setVisible(false)
        } else if (scrolled <= 0) {
            setVisible(true)
        }
    };

    useEffect(() => {
        
        if(queryParams.get("endtime")){
            makeLinkExpired()
            const interval = setInterval(() => {
                makeLinkExpired()
            }, 5000);
            return () => clearInterval(interval);
        }
        
    }, []);

    const makeLinkExpired = () => {

        if(queryParams.get("endtime")){
            var time =  moment(queryParams.get("endtime"));
            var now = moment();

            console.log(time,now) 

            if(time.isBefore(now)){
                setIsLocationShared(false)
            }
            else{
                console.log('notNow')
            }
        }

    }

    useEffect(() => {
        getUsersLocation()

        if(queryParams.get("comment")){
            setComment(queryParams.get("comment"))
        }
    }, [])

    useEffect(() => {
        destinationMarker && window.addEventListener('scroll', toggleVisible);
    }, [destinationMarker])

    const getUsersLocation = () => {
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(showPosition, showError);
        } else {
            setLocationPermissionGranted(false)
        }
    }

    const showPosition = (position) => {
        setLocationPermissionGranted(true)
        setCurrentUserLocation({
            latitude: position.coords.latitude,
            longitude: position.coords.longitude
        })
    }

    const showError = (error) => {
        setLocationPermissionGranted(false)
    }

    useEffect(() => {
        if (destinationUserLocation && destinationUserLocation.userId && !destinationUserData) {
            fetch(`${URL}/safetyplan/browser-details?userId=${destinationUserLocation.userId} `)
                .then(res => res.json())
                .then(res => {
                    
                    if (res.err) {
                        setIsLocationShared(false)
                    } else {

                        if(res.data.alert ===  true && checkInType === "checkIn"){
                            setIsLocationShared(false)
                        }
                        else if (checkInType === "checkIn" && !res.data.cover_radius && !res.data.location) {                            
                            setIsLocationShared(false)
                        } else {
                            setDestinationUserData(res.data)
                        }
                        
                    }
                })
                .catch(err => {
                    setIsLocationShared(false)
                })
        }

    }, [destinationUserLocation])
    // New code used for map show

    useEffect(() => {

        const nlat = queryParams.get("lat")
        const nlong = queryParams.get("long")
        const ntype = queryParams.get("type")

        if(destinationUserLocation && checkInType){

            let map
            if(ntype == 'liveNow'){
                console.log(destinationUserLocation.longitude, destinationUserLocation.latitude,'Map')

                map = new mapboxgl.Map({
                    container: mapContainerRef.current,
                    // style: "mapbox://styles/aegis247/clahqzswz000c14r1nqj88xta",
                    style: "mapbox://styles/aegis247/clg49oibr006i01oajkvd8vz0",
                    center: [destinationUserLocation.longitude, destinationUserLocation.latitude],
                    zoom: 15,
                    accessToken: MAPBOX_TOKEN,
                })
            }
            else{
                map = new mapboxgl.Map({
                    container: mapContainerRef.current,
                    // style: "mapbox://styles/aegis247/clahqzswz000c14r1nqj88xta",
                    style: "mapbox://styles/aegis247/clg49oibr006i01oajkvd8vz0",
                    center: [nlong, nlat],
                    zoom: 15,
                    accessToken: MAPBOX_TOKEN,
                })
            }

            map.addControl(geoLocationController);
            map.addControl(new mapboxgl.NavigationControl(), "top-right");

            if (ntype === "live" || ntype === "liveLoop") {
                
                const dlat = queryParams.get("dlat")
                const dlong = queryParams.get("dlong")

                const metersPerPixel = (latitude, zoomLevel) => {
                    var earthCircumference = 40075017;
                    var latitudeRadians = latitude * (Math.PI / 180);
                    return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
                };

                const pixelValue = (latitude, meters, zoomLevel) => {
                    return meters / metersPerPixel(latitude, zoomLevel);
                };

                map.on('load', function () {
                    map.addSource("source_circle_500", {
                        "type": "geojson",
                        "data": {
                            "type": "FeatureCollection",
                            "features": [{
                                "type": "Feature",
                                "geometry": {
                                    "type": "Point",
                                    "coordinates": [dlong, dlat]
                                }
                            }]
                        }
                    });

                    map.addLayer({
                        "id": "circle500",
                        "type": "circle",
                        "source": "source_circle_500",
                        "paint": {
                            "circle-radius": {
                                stops: [
                                    [0, 0],
                                    [23, pixelValue(dlat, 30, 23)]
                                ],
                                base: 2
                            },
                            "circle-color": "#B037B6",
                            "circle-opacity": 0.2
                        }
                    });
                })
            }

            let el = document.createElement('div');
            let pulse = document.createElement("div");
            let icon = document.createElement("div")
            el.appendChild(pulse);
            el.appendChild(icon)
            pulse.className = 'pulse'
            icon.className = 'marker';
            icon.style.backgroundImage = `url(${MarkerIcon})`;
            icon.style.width = '21px';
            icon.style.height = '39px';
            icon.style.backgroundRepeat = 'no-repeat';
            icon.style.zIndex = '2'

            let marker;
            

            if(ntype == 'liveNow'){
                console.log(destinationUserLocation.longitude, destinationUserLocation.latitude,'Marker')
                marker = new mapboxgl.Marker(el).setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).addTo(map)
            }
            else{
                marker = new mapboxgl.Marker(el).setLngLat([nlong, nlat]).addTo(map)
            }

            setDestinationMarker(marker)

            // map.getSource('polygon').setData(createGeoJSONCircle([-93.6248586, 41.58527859], 1).data);
            setMAP(map)
        }

    }, [destinationUserLocation && checkInType])


    useEffect(() => {

        // const queryParams = new URLSearchParams(window.location.search)
        const lat = queryParams.get("lat")
        const long = queryParams.get("long")
        const docId = queryParams.get("uniqueId")
        const userId = queryParams.get("userId")
        const type = queryParams.get("type")
        
        if(isLocationShared){
            if (currentUserLocation && lat && long) {
                if (docId && (type === "help" || type === "live" || type === "liveLoop" )) {

                    console.log(type ,'data-from-firebase')
                    const auth = getAuth()

                    signInAnonymously(auth)
                        .then(() => {
                            onSnapshot(doc(db, FIREBASE_TABLE, docId), (doc) => {
                                if (doc.data()) {
                                    let data = doc.data()
                                    setDestinationUserLocation(data)
                                } else {
                                    setIsLocationShared(false)
                                }
                            });
                        })
                        .catch((error) => {
                            console.log(error,'firebaseError');
                            setIsLocationShared(false)
                        });


                } else if (docId && (type === "liveNow" )) {

                    const auth = getAuth()
                    signInAnonymously(auth)
                        .then(() => {
                            onSnapshot(doc(db, FIREBASE_TABLE, docId), (doc) => {
                                if (doc.data()) {
                                    var data = doc.data()
                                    
                                    setDestinationUserLocation(data)
                                } else {
                                    setIsLocationShared(false)
                                }
                            });
                        })
                        .catch((error) => {
                            console.log(error,'firebaseError');
                            setIsLocationShared(false)
                        });


                } else if (type === "checkIn") {
                    setDestinationUserLocation({
                        longitude: long,
                        latitude: lat,
                        userId: docId
                    })
                } else if (type === "static") {
                    setDestinationUserLocation({
                        longitude: long,
                        latitude: lat,
                        userId: userId
                    })

                } else {
                    setIsLocationShared(false)
                }

                setCheckInType(type)


                // Old code used for map show

                // let map = new mapboxgl.Map({
                //     container: mapContainerRef.current,
                //     // style: "mapbox://styles/aegis247/clahqzswz000c14r1nqj88xta",
                //     style: "mapbox://styles/aegis247/clg49oibr006i01oajkvd8vz0",
                //     center: [long,lat],
                //     zoom: 15,
                //     accessToken: MAPBOX_TOKEN,
                // })
                


        
                // map.addControl(geoLocationController);
                // map.addControl(new mapboxgl.NavigationControl(), "top-right");

                // if (type === "live" || type === "liveLoop") {
                    
                //     const dlat = queryParams.get("dlat")
                //     const dlong = queryParams.get("dlong")

                //     const metersPerPixel = (latitude, zoomLevel) => {
                //         var earthCircumference = 40075017;
                //         var latitudeRadians = latitude * (Math.PI / 180);
                //         return earthCircumference * Math.cos(latitudeRadians) / Math.pow(2, zoomLevel + 8);
                //     };

                //     const pixelValue = (latitude, meters, zoomLevel) => {
                //         return meters / metersPerPixel(latitude, zoomLevel);
                //     };

                //     map.on('load', function () {
                //         map.addSource("source_circle_500", {
                //             "type": "geojson",
                //             "data": {
                //                 "type": "FeatureCollection",
                //                 "features": [{
                //                     "type": "Feature",
                //                     "geometry": {
                //                         "type": "Point",
                //                         "coordinates": [dlong, dlat]
                //                     }
                //                 }]
                //             }
                //         });

                //         map.addLayer({
                //             "id": "circle500",
                //             "type": "circle",
                //             "source": "source_circle_500",
                //             "paint": {
                //                 "circle-radius": {
                //                     stops: [
                //                         [0, 0],
                //                         [23, pixelValue(dlat, 30, 23)]
                //                     ],
                //                     base: 2
                //                 },
                //                 "circle-color": "#B037B6",
                //                 "circle-opacity": 0.2
                //             }
                //         });
                //     })
                // }

                // let el = document.createElement('div');
                // let pulse = document.createElement("div");
                // let icon = document.createElement("div")
                // el.appendChild(pulse);
                // el.appendChild(icon)
                // pulse.className = 'pulse'
                // icon.className = 'marker';
                // icon.style.backgroundImage = `url(${MarkerIcon})`;
                // icon.style.width = '21px';
                // icon.style.height = '39px';
                // icon.style.backgroundRepeat = 'no-repeat';
                // icon.style.zIndex = '2'

                // let marker  = new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(map)
                

                // setDestinationMarker(marker)

                // // map.getSource('polygon').setData(createGeoJSONCircle([-93.6248586, 41.58527859], 1).data);
                // setMAP(map)

            } else if (currentUserLocation) {
                setIsLocationShared(false)
            }
        }

    }, [currentUserLocation])


    useEffect(() => {
        if (destinationUserLocation && MAP) {
            const destinationUser = React.createRef();
            destinationUser.current = ReactDOM.createRoot(document.createElement("div"));
            destinationUser.current.render(
                <Marker
                />
            );
            let startDate = new Date(destinationUserData?.start_time);
            let endDate = new Date(destinationUserData?.end_time);
            const queryParams = new URLSearchParams(window.location.search)
            const formattedStartDate = startDate.toLocaleDateString('en-GB', {
                weekday: 'long', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true
            })
            const formattedEndDate = endDate.toLocaleDateString('en-GB', {
                weekday: 'long', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true
            })
            let newPopUp = null
            if (destinationUserData) {


                const planType = queryParams.get("planType") ?? 'safetyplan'
                const planExist = queryParams.get("planExist") === 'true'
                //comment old HTML to show help types
                // <span class="planType">${destinationUserData.cover_radius?'Safety Plan (Auto)':'Safety Plan (Manual)'}</span>

                if (checkInType === "help") {
                    destinationMarker.getElement().addEventListener('click', () => {
                        fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${destinationUserLocation.longitude}%2C${destinationUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            .then(res => res.json())
                            .then(res => {
                                if (res.err) {
                                    setIsLocationShared(false)
                                } else {
                                    newPopUp = new mapboxgl.Popup({ focusAfterOpen: false }) // add popups
                                        .setHTML(
                                            `<div class="detailPopUp">
                <img class="logo" src=${Aegis} />
                <hr />
                <div class="mt-3">
                <div class="helpIconBox"><img class="helpIcon" src=${HelpIcon} /></div>
                <span class="helpTitle">Help Alert</span>
                </div>
                <div class="mt-4">
                    <span class="title me-3">Help activation type:</span>
                    <span class="planType">${planType == 'manual'? (planExist ? 'Manual (Safety Plan)' : 'Manual (No Safety Plan)' ): 'Auto (Safety Plan)' }</span>
                </div>
                <hr />
                <div>
                    <div class="headerTitle">Person requesting help</div>
                    <div class="fieldContainer">
                        <span class="title">Name:</span>
                        <span class="fieldValue">${destinationUserData.name ? destinationUserData.name : "--"}</span>
                    </div>
                    <div class="fieldContainer">
                        <span class="title">Cell:</span>
                        <span class="fieldValue">${destinationUserData.cell ? destinationUserData.cell : '--'}</span>
                    </div>
                </div>
                <hr />
                <div >
                    <div class="headerTitle">Person's current location</div>
                    <div class="title">${res?.features?.length ? res.features[0].place_name : '--'}</div>
                </div>
                <div class="disclamer">
                    <span class="fieldValueGrey"> A person's exact location is affected by buildings and environment. Open sky locations have greater accuracy.</span>
                </div>
                <hr />
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                            <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Safety Plan
                            </button>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="fieldContainer">
                                    <span class="title">Location:</span>
                                    <span class="fieldValue">${destinationUserData.location ? destinationUserData.location : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Safety area:</span>
                                    <span class="fieldValue">${destinationUserData.cover_radius ? Math.floor(+destinationUserData.cover_radius * 3.28084) + 'ft' : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Person(s) at location:</span>
                                    <span class="fieldValue">${destinationUserData.person_name?.length ? destinationUserData.person_name.join(', ') : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title location-width">Time at location:</span>
                                    <div>
                                    <span class="fieldValue d-block">${destinationUserData.start_time ? "Start " + formattedStartDate : '--'}</span>
                                    <span class="fieldValue">${destinationUserData.end_time ? "End " + formattedEndDate : '--'}</span>
                                    </div>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Contacts to notify for help (#):</span>
                                    <span class="fieldValue">${destinationUserData.contacts ? destinationUserData.contacts : '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                More
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="fieldContainer">
                                    <span class="title">Altitude :</span>
                                    <span class="fieldValue">${destinationUserLocation.altitude !== null ? Math.floor(+destinationUserLocation.altitude * 3.28084) + 'ft' : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Battery %:</span>
                                    <span class="fieldValue">${destinationUserData.battery ? destinationUserData.battery : '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
                                        ).on('open', () => {
                                            setVisible(true)
                                        }).on('close', () => {
                                            setVisible(false)
                                        })
                                    destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).setPopup(newPopUp).addTo(MAP).togglePopup()
                                }
                            })
                            .catch(err => {
                                setIsLocationShared(false)
                            })
                    })
                    destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude])
                } else if (checkInType === "checkIn") {
                    const battery = queryParams.get("battery")
                    const altitude = queryParams.get("altitude")
                    let startDate = new Date(destinationUserData?.start_time);
                    let endDate = new Date(destinationUserData?.end_time)
                    const formattedStartDate = startDate.toLocaleDateString('en-GB', {
                        weekday: 'long', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true
                    })
                    const formattedEndDate = endDate.toLocaleDateString('en-GB', {
                        weekday: 'long', day: 'numeric', month: 'short', hour: '2-digit', minute: '2-digit', hour12: true
                    })
                    newPopUp = new mapboxgl.Popup({ focusAfterOpen: false }) // add popups
                        .setHTML(
                            `<div class="detailPopUp">
                <img class="logo" src=${Aegis} />
                <hr />
                <div class="mt-3">
                <div class="helpIconBox"><img class="helpIcon" src=${HelpIcon} /></div>
                <span class="helpTitle">Check In</span>
                </div>
                <hr />
                <div>
                    <div class="headerTitle">Person</div>
                    <div class="fieldContainer">
                        <span class="title">Name:</span>
                        <span class="fieldValue">${destinationUserData.name ? destinationUserData.name : "--"}</span>
                    </div>
                    <div class="fieldContainer">
                        <span class="title">Cell:</span>
                        <span class="fieldValue">${destinationUserData.cell ? destinationUserData.cell : '--'}</span>
                    </div>
                </div>
                <hr />
                <div class="accordion" id="accordionExample">
                    <div class="accordion-item">
                            <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                Safety Plan
                            </button>
                        <div id="collapseOne" class="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="fieldContainer">
                                    <span class="title">Location:</span>
                                    <span class="fieldValue">${destinationUserData.location ? destinationUserData.location : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Safety area:</span>
                                    <span class="fieldValue">${destinationUserData.cover_radius ? Math.floor(+destinationUserData.cover_radius * 3.28084) + 'ft' : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Person(s) at location:</span>
                                    <span class="fieldValue">${destinationUserData.person_name?.length ? destinationUserData.person_name.join(', ') : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title location-width">Time at location:</span>
                                    <div>
                                    <span class="fieldValue d-block">${destinationUserData.start_time ? "Start " + formattedStartDate : '--'}</span>
                                    <span class="fieldValue">${destinationUserData.end_time ? "End " + formattedEndDate : '--'}</span>
                                    </div>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Contacts to notify for help (#):</span>
                                    <span class="fieldValue">${destinationUserData.contacts ? destinationUserData.contacts : '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div class="accordion-item">
                        <h2 class="accordion-header" id="headingTwo">
                            <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                More
                            </button>
                        </h2>
                        <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                            <div class="accordion-body">
                                <div class="fieldContainer">
                                    <span class="title">Altitude :</span>
                                    <span class="fieldValue">${altitude ? Math.floor(+altitude * 3.28084) + 'ft' : '--'}</span>
                                </div>
                                <div class="fieldContainer">
                                    <span class="title">Battery %:</span>
                                    <span class="fieldValue">${battery ? battery : '--'}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>`
                        ).on('open', () => {
                            setVisible(true)
                        }).on('close', () => {
                            setVisible(false)
                        })
                    destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).setPopup(newPopUp).addTo(MAP)

                } else if (checkInType === "static") {
                    const getUserLocation = async () => {
                        try {
                            let response = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${destinationUserLocation.longitude}%2C${destinationUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            response = await response.json()
                            if (response?.features?.length) {

                                setLocation(response.features[0].place_name)
                                newPopUp = new mapboxgl.Popup({ focusAfterOpen: false }) // add popups
                                    .setHTML(
                                        `<div class="detailPopUp">
    <img class="logo" src=${Aegis} />
    <hr />
    <div class="mt-3">
    <div class="helpIconBox"><img class="helpIcon" src=${ShareIcon} alt="" /></div>
    <span class="helpTitle">Location pin share</span>
    </div>
   
    <hr />
    <div>
        <div class="headerTitle">Person static sharing</div>
        <div class="fieldContainer">
            <span class="title">Name:</span>
            <span class="fieldValue">${destinationUserData.name ? destinationUserData.name : "--"}</span>
        </div>
        <div class="fieldContainer">
            <span class="title">Cell:</span>
            <span class="fieldValue">${destinationUserData.phone_number ? `${destinationUserData?.country_code}` + `${destinationUserData.phone_number}` : '--'}</span>
        </div>
    </div>
    <hr />
    <div >
        <div class="headerTitle">Static location</div>
        <div class="title">${response?.features?.length ? response.features[0].place_name : '--'}</div>
    </div>
    <hr />
    <div class="accordion" id="accordionExample">
        <div class="accordion-item">
            <h2 class="accordion-header" id="headingTwo">
                <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                    More
                </button>
            </h2>
            <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                <div class="accordion-body">
                    <div class="fieldContainer">
                        <span class="title">Altitude :</span>
                        <span class="fieldValue">${destinationUserData.altitude !== null ? Math.floor(+destinationUserData.altitude * 3.28084) + 'ft' : '--'}</span>
                    </div>
                    <div class="fieldContainer">
                        <span class="title">Battery %:</span>
                        <span class="fieldValue">${destinationUserData.battery ? destinationUserData.battery : '--'}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />
    <div>
        <span class="fieldValueGrey"> Location pin shared for 1 hour.</span>
    </div>
</div>`
                                    ).on('open', () => {
                                        setVisible(true)
                                    }).on('close', () => {
                                        setVisible(false)
                                    })
                                destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).setPopup(newPopUp).addTo(MAP)
                            }
                        } catch (e) {
                            setIsLocationShared(false)
                        }
                    }
                    getUserLocation()
                } else if (checkInType === "live" || checkInType === "liveLoop" ) {
                
                    const getUserLocation = async () => {
                        try {
                            let helpUserLocation = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${destinationUserLocation.longitude}%2C${destinationUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            helpUserLocation = await helpUserLocation.json()
                            let currentLocation = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${currentUserLocation.longitude}%2C${currentUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            currentLocation = await currentLocation.json()
                            const queryParams = new URLSearchParams(window.location.search)
                            const lat = queryParams.get("dlat")
                            const long = queryParams.get("dlong")
                            let destinationPoint = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${long}%2C${lat}.json?access_token=${MAPBOX_TOKEN}`)
                            destinationPoint = await destinationPoint.json()
                            if (destinationPoint?.features?.length && helpUserLocation?.features?.length) {
                                newPopUp = new mapboxgl.Popup({ focusAfterOpen: false }) // add popups
                                    .setHTML(
                                        `<div class="detailPopUp">
        <img class="logo" src=${Aegis} />
        <hr />
        <div class="mt-3">
        <div class="helpIconBox"><img class="helpIcon" src=${ShareIcon} alt="" /></div>
        <span class="helpTitle">${checkInType == 'live' ? "Live To Destination" : " Live Loop"}</span>
        </div>
       
        <hr />
        <div>
            <div class="headerTitle">Person live sharing</div>
            <div class="fieldContainer">
                <span class="title">Name:</span>
                <span class="fieldValue">${destinationUserData.name ? destinationUserData.name : "--"}</span>
            </div>
            <div class="fieldContainer">
                <span class="title">Cell:</span>
                <span class="fieldValue">${destinationUserData.phone_number ? `${destinationUserData?.country_code}` + `${destinationUserData.phone_number}` : '--'}</span>
            </div>
        </div>
        <hr />
        <div >
            <div class="headerTitle">${destinationUserData.name?.split(' ')[0]}'s Current location</div>
            <div class="title">${helpUserLocation?.features?.length ? helpUserLocation.features[0].place_name : '--'}</div>
        </div>
        <div class="disclamer">
            <span class="fieldValueGrey"> A person's exact location is affected by buildings and environment. Open sky locations have greater accuracy.</span>
        </div>
        <hr />
        <div >
            <div class="headerTitle">Destination location</div>
            <div class="title">${destinationPoint?.features?.length ? destinationPoint.features[0].place_name : '--'}</div>
        </div>
        <hr />
        <div >
            <div class="headerTitle">My current location</div>
            <div class="title">${currentLocation?.features?.length ? currentLocation.features[0].place_name : '--'}</div>
        </div>
        <hr />
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        More
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="fieldContainer">
                            <span class="title">Altitude :</span>
                            <span class="fieldValue">${destinationUserLocation.altitude !== null ? Math.floor(+destinationUserLocation.altitude * 3.28084) + 'ft' : '--'}</span>
                        </div>
                        <div class="fieldContainer">
                            <span class="title">Battery %:</span>
                            <span class="fieldValue">${destinationUserData.battery ? destinationUserData.battery : '--'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>`
                                    ).on('open', () => {
                                        setVisible(true)
                                    }).on('close', () => {
                                        setVisible(false)
                                    })
                                let el = document.createElement('div');
                                let icon = document.createElement('div')
                                el.appendChild(icon)

                                icon.className = 'flag-marker';
                                icon.style.backgroundImage = `url(${FlagIcon})`;
                                icon.style.width = '27px';
                                icon.style.height = '39px';
                                icon.style.backgroundRepeat = 'no-repeat';
                                icon.style.backgroundSize = 'contain'
                                icon.style.zIndex = '1'
                                let midPointmarker = new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(MAP)
                                setIsMidPointMarker(true)
                                destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).setPopup(newPopUp).addTo(MAP)
                            }
                        } catch (e) {
                            setIsLocationShared(false)
                        }
                    }
                    !isMidPointMarker && getUserLocation()
                    destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude])

                } else if (checkInType === "liveNow") {
                    const getUserLocation = async () => {
                        try {
                            let helpUserLocation = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${destinationUserLocation.longitude}%2C${destinationUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            helpUserLocation = await helpUserLocation.json()
                            let currentLocation = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${currentUserLocation.longitude}%2C${currentUserLocation.latitude}.json?access_token=${MAPBOX_TOKEN}`)
                            currentLocation = await currentLocation.json()
                            const queryParams = new URLSearchParams(window.location.search)
                            const lat = queryParams.get("dlat")
                            const long = queryParams.get("dlong")
                            let destinationPoint = await fetch(`https://api.mapbox.com/geocoding/v5/mapbox.places/${long}%2C${lat}.json?access_token=${MAPBOX_TOKEN}`)
                            destinationPoint = await destinationPoint.json()
                            if (destinationPoint?.features?.length && helpUserLocation?.features?.length) {
                                newPopUp = new mapboxgl.Popup({ focusAfterOpen: false }) // add popups
                                    .setHTML(
                                        `<div class="detailPopUp">
        <img class="logo" src=${Aegis} />
        <hr />
        <div class="mt-3">
        <div class="helpIconBox"><img class="helpIcon" src=${ShareIcon} alt="" /></div>
        <span class="helpTitle">Live Now</span>
        </div>
       
        <hr />
        <div>
            <div class="headerTitle">Person live sharing</div>
            <div class="fieldContainer">
                <span class="title">Name:</span>
                <span class="fieldValue">${destinationUserData.name ? destinationUserData.name : "--"}</span>
            </div>
            <div class="fieldContainer">
                <span class="title">Cell:</span>
                <span class="fieldValue">${destinationUserData.phone_number ? `${destinationUserData?.country_code}` + `${destinationUserData.phone_number}` : '--'}</span>
            </div>
        </div>
        <hr />
        <div >
            <div class="headerTitle">${destinationUserData.name?.split(' ')[0]}'s Current location</div>
            <div class="title">${helpUserLocation?.features?.length ? helpUserLocation.features[0].place_name : '--'}</div>
        </div>
        <div class="disclamer">
            <span class="fieldValueGrey"> A person's exact location is affected by buildings and environment. Open sky locations have greater accuracy.</span>
        </div>
        <hr />
        <div>
        <div class="headerTitle">Sharing duration</div>
            <div class="fieldContainer">
                <span class="title">Start:</span>
                <span class="fieldValue">${destinationUserLocation.startTime ? moment(destinationUserLocation.startTime).format('dddd hh:mm a') : "00:00"}</span>
            </div>
            <div class="fieldContainer">
                <span class="title">End:</span>
                <span class="fieldValue">${destinationUserLocation.endTime ? moment(destinationUserLocation.endTime).format('dddd hh:mm a') : "00:00"}</span>
            </div>
        </div>
        <hr />
        <div >
            <div class="headerTitle">My current location</div>
            <div class="title">${currentLocation?.features?.length ? currentLocation.features[0].place_name : '--'}</div>
        </div>
        <hr />
        <div class="accordion" id="accordionExample">
            <div class="accordion-item">
                <h2 class="accordion-header" id="headingTwo">
                    <button class="accordion-button headerTitle collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                        More
                    </button>
                </h2>
                <div id="collapseTwo" class="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                    <div class="accordion-body">
                        <div class="fieldContainer">
                            <span class="title">Altitude :</span>
                            <span class="fieldValue">${destinationUserLocation.altitude !== null ? Math.floor(+destinationUserLocation.altitude * 3.28084) + 'ft' : '--'}</span>
                        </div>
                        <div class="fieldContainer">
                            <span class="title">Battery %:</span>
                            <span class="fieldValue">${destinationUserData.battery ? destinationUserData.battery : '--'}</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>`
                                    ).on('open', () => {
                                        setVisible(true)
                                    }).on('close', () => {
                                        setVisible(false)
                                    })
                                let el = document.createElement('div');
                                let icon = document.createElement('div')
                                el.appendChild(icon)

                                icon.className = 'flag-marker';
                                icon.style.backgroundImage = `url(${FlagIcon})`;
                                icon.style.width = '27px';
                                icon.style.height = '39px';
                                icon.style.backgroundRepeat = 'no-repeat';
                                icon.style.backgroundSize = 'contain'
                                icon.style.zIndex = '1'
                                let midPointmarker = new mapboxgl.Marker(el).setLngLat([long, lat]).addTo(MAP)
                                setIsMidPointMarker(true)
                                destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude]).setPopup(newPopUp).addTo(MAP)
                            }
                        } catch (e) {
                            setIsLocationShared(false)
                        }
                    }
                    !isMidPointMarker && getUserLocation()
                    destinationMarker.setLngLat([destinationUserLocation.longitude, destinationUserLocation.latitude])

                }
            }
        }
    }, [destinationUserLocation, MAP, destinationUserData])

    return (
        <>
            {
                locationPermissionGranted ?
                isLocationShared
                        ? 
                        <>
                        <div ref={mapContainerRef} className="map-container" >
                            {location &&
                                <div className="main_info">
                                    <div className="main_location mb-2 mt-2">
                                        <span className="innerShortTxt">
                                            <b>{location}</b>
                                        </span>
                                        <img className="copyIcon ms-2" src={copyIcon} alt="copy" title={location} 
                                            onClick={() => {
                                                navigator.clipboard.writeText(location);
                                                toast.success('Location copied.');
                                            }}
                                        />
                                    </div>
                                    {comment && 
                                        <div className="main_comment">
                                            <span className="innerShortTxt">
                                                {comment}
                                            </span>
                                        </div>
                                    }
                                </div>
                            }
                        </div>
                        </>
                        : 
                        <LocationStopped />
                    : 
                    <LocationPermission />
            }
            {
                visible && locationPermissionGranted && isLocationShared && 
                <div className='scrolldown'>
                    Scroll down for more
                    <img src={DownIcon} alt='' className='mx-2' />
                </div>
            }
        </>
    );
};

export default Location;