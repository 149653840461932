import React from "react";
import AgesICon from "../Assets/Images/logo.svg";
import locationSVG from "../Assets/Images/location-stopped.svg";
import locationPNG from "../Assets/Images/location-stopped.png";
import companyLogo from "../Assets/Images/company-logo.png";

const LocationStopped = () => {
  return (
    <>
      <div className="mainBox text-center">
        <div className="loactionbox_">
        <figure className="mb-0">
          <img src={companyLogo} alt="" className="img-fluid logostopped" />
        </figure>
        <div className="midlocatiobBox_">
        <figure className="mb-0">
          <img src={locationPNG} className="location-svg img-fluid" alt="" />
        </figure>
        </div>
        <div className="locationBottomn_">
        <h5 className="title">Location sharing stopped</h5>
        <p className="sub-title">
          To make your personal safety simple, download the app.
        </p>
        <a href="https://apps.apple.com/us/app/id6444657540" className="custom-button downloadBtn_" >
          Download the app
        </a>
        </div>
      </div>
      </div>
    </>
  );
};

export default LocationStopped;
