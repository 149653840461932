import logo from "../public/img/logo.svg";
import LoginLeft from "../public/img/loginLeft.svg";
import Message from "../public/img/Message.svg";
import Lock from "../public/img/Lock.svg";
import eyeIcon from "../public/img/eyeIcon.svg";
import backIcon from "../public/img/backIcon.svg";
import hideIcon from "../public/img/Hide.svg";
import Correct from "../public/img/correct.svg";
import Wrong from "../public/img/wrong.svg";
import User from "../public/img/userIcon.svg";
import Welcome from "../public/img/welcome.svg";
import EditIcon from "../public/img/Edit.svg";
import Logout from "../public/img/Logout.svg";
import Search from "../public/img/Search.svg";
import Sync from "../public/img/syncIcon.svg";
import BackButton from "../public/img/back_button.svg";
import EditProfile from "../public/img/Editprofile.svg";
import changeProfile from "../public/img/changeProfile.svg";
import changePassword from "../public/img/changePassword.svg";
import Profile from "../public/img/Profile.svg"
import Setting from "../public/img/Setting.svg"
import ActivityIcon from "../public/img/ActivityIcon.svg"
import Calendar from "../public/img/Calendar.svg"
import FillEdit from "../public/img/FillEdit.svg"
import ArrowDown from "../public/img/ArrowDown.svg"
import RateIcon from "../public/img/RateIcon.svg"
import CanceIcon from "../public/img/CanceIcon.svg"
import AddMore from "../public/img/AddMore.svg"
import AddIcon from "../public/img/addBill.svg"
import MobileFilter from "../public/img/mobileFilter.svg"

export {
  logo,
  LoginLeft,
  Lock,
  eyeIcon,
  backIcon,
  hideIcon,
  Correct,
  Wrong,
  User,
  Welcome,
  EditIcon,
  Logout,
  Search,
  Sync,
  BackButton,
  EditProfile,
  changeProfile,
  changePassword,
  Profile,
  Setting,
  ActivityIcon,
  Calendar,
  FillEdit,
  ArrowDown,
  RateIcon,
  CanceIcon,
  AddMore,
  AddIcon,
  MobileFilter,
  Message,
}