import React from 'react';
import {Navigate, Outlet, useNavigate } from 'react-router-dom';
import AdminNav from '../Components/Common/adminNav';
import { useAuthSelector } from '../redux/selector/auth';

const AdminAuthLayout = () => {

  const navigate = useNavigate()
  const auth = useAuthSelector()
  const token = localStorage.getItem('authToken')
  const isLoggedIn = auth.isLoggedIn

  return (
    <>    
        <AdminNav />
        <div className="commonrightBox">
          <div className='container'>
              {token && isLoggedIn ? <Outlet/> : <Navigate to="/admin/login" />}
          </div>
        </div>
    </>
  );

};

export default AdminAuthLayout;