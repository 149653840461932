import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {},
    isLoggedIn: false,
    loading: false
}

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: ({
        userLogin: (state) => {
            state.loading = true
        },
        setUserLogin: (state, action) => {
            state.loading = false
            state.isLoggedIn = true
            state.userInfo = action.payload        
        },
        forgotPassword:(state)=>{
            state.loading = true
        },
        setForgotPassword:(state,action)=>{
            state.loading = false
        },
        codeVerify: (state) => {
            state.loading = true
        },
        setCodeVerify:(state,action)=>{
            state.loading = false
            state.isLoggedIn = false
            state.userInfo = action.payload    
        },
        resetPassword:(state)=>{
            state.loading = true
        },
        setResetPassword:(state)=>{
            state.loading = false
            state.isLoggedIn = false
            state.userInfo = {}  
        },
        changePasswordAction:(state)=>{
            state.loading = true
        },
        setChangePassword:(state)=>{
            state.loading = false
        },
        updateProfileAction:(state)=>{
            state.loading = true
        },
        setUpdateProfile:(state,action)=>{
            state.loading = false
            state.userInfo.name = action.payload.name
            state.userInfo.email = action.payload.email
        },
        logout: (state) => {
            state.loading = true
        },
        setLogout: (state) => {
            state.loading = false
            state.userInfo = {}
            state.isLoggedIn = false
        },
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }),
})

// Action creators are generated for each case reducer function
export const { userLogin, setUserLogin, forgotPassword, setForgotPassword , codeVerify, setCodeVerify, resetPassword, setResetPassword, changePasswordAction, updateProfileAction, setUpdateProfile ,setChangePassword, onErrorStopLoad, logout, setLogout  } = authSlice.actions

export default authSlice.reducer