import React from "react";
import Location from "./Components/Location";
import { Route, Routes } from "react-router-dom";
import * as Layouts from "./Layouts"
import * as Containers from "./Containers"
import './public/css/custom.css';
import './public/css/login.css';
import 'react-confirm-alert/src/react-confirm-alert.css'; 
import './public/css/responsive.css';

export default function App() {

  return (
    <>
      <Routes>
        
        <Route path="/" element={<Location />} />
        
        <Route path="/admin" element={<Layouts.AdminLayout/>} >
            <Route path="" element={<Containers.Login />} />
            <Route path="login" element={<Containers.Login />} />
            <Route path="forgotPassword" element={<Containers.ForgotPassword />} />
            <Route path="otpVerification" element={<Containers.OtpVerification />} />
            <Route path="recoverPassword" element={<Containers.RecoverPassword />} />

            <Route element={<Layouts.AdminAuthLayout/>} >
              <Route path="dashboard" element={<Containers.Dashboard/>} />
              <Route path="users/:currentUserStatus" element={<Containers.Users/>} />
              <Route path="users/changePassword" element={<Containers.ChangePassword/>} />
              <Route path="users/updateProfile" element={<Containers.UpdateProfile/>} />
            </Route>
        </Route>

      </Routes>
    </>
  )

}