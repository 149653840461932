import { createSlice } from '@reduxjs/toolkit'

const initialState = {
    userInfo: {},
    isLoggedIn: false,
    loading: false
}

export const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: ({
        userList: (state) => {
            state.loading = true
        },
        allUserList: (state) => {
            state.loading = true
        },
        setUserList: (state, action) => {
            state.loading = false
        }, 
        setAllUserList: (state) => {
            state.loading = false
        },
        countList: (state) => {
            state.loading = true
        },
        setCountList: (state, action) => {
            state.loading = false  
        }, 
        deleteUser: (state) => {
            state.loading = true
        },
        setDeleteUser: (state, action) => {
            state.loading = false  
        }, 
        suspendUser: (state) => {
            state.loading = true
        },
        setSuspendUser: (state, action) => {
            state.loading = false  
        }, 
        logOutUser: (state) => {
            state.loading = true
        },
        setlogOutUser: (state, action) => {
            state.loading = false  
        }, 
        onErrorStopLoad: (state) => {
            state.loading = false
        }
    }) 
})

// Action creators are generated for each case reducer function
export const { userList, allUserList, setAllUserList, setUserList, countList, setCountList, deleteUser, setDeleteUser ,suspendUser,setSuspendUser, logOutUser, setlogOutUser, onErrorStopLoad} = userSlice.actions

export default userSlice.reducer