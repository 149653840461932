import React, { useState } from "react";
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { userLogin } from "../../redux/slices/auth";
import * as Images from "../../utilities/images";

const Login = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [errorMessages, setErrorMessages] = useState({});
    const [formData, setFormData] = useState({
        email: "",
        password: "",
        role: 2,

    });
    
    const [passwordShown, setPasswordShown] = useState(false);
    //onchange input 
    const handleChange = (e) => {
        const { value, name } = e.target;
        setFormData({ ...formData, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    //validation form on email password 
    const validateForm = () => {
        let errors = {};
        let formIsValid = true;
        if (!formData.email) {
            formIsValid = false;
            errors = { ...errors, email: "Please enter your email." };
        }
        else if (typeof formData.email !== "undefined") {
            var pattern = new RegExp(/^(("[\w-\s]+")|([\w-]+(?:\.[\w-]+)*)|("[\w-\s]+")([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i);
            if (!pattern.test(formData.email.trim())) {
                formIsValid = false;
                errors = { ...errors, email: "Please enter valid email." };
            }
        }
        if (!formData.password) {
            formIsValid = false;
            errors = { ...errors, password: "Please enter your password." };
        }
        setErrorMessages(errors);
        return formIsValid;
    }

    //form login
    const handleSubmit = () => {
        
        if (!formData.email) {
            toast.error("Please enter email address");
            return;
        }
        else if (formData.email && !/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(formData.email)) {
            toast.error("Please enter valid email address");
            return;
        }
        else if (!formData.password) {
            toast.error("Please enter password");
            return;
        }
        else if (formData.password.length <= 7) {
            toast.error("Password should be maximum 8 character")
            return;
        }
        if (validateForm) {
            let params = {
                email: formData.email.trim(),
                password: formData.password,
                role_id: formData.role_id
            }
            console.log('params', params);
            dispatch(userLogin({
                ...params, cb(res) {
                    if (res.status == 200) {

                        console.log(res.data.data,'reponse')
                       
                        navigate('/admin/dashboard');

                    }
                    else {

                    }
                }
            }))
        }
    }

    return (
        <>
            <div className='login'>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="logo_icon">
                            <img src={Images.logo} alt='Location' className='logo_img' />
                        </div>
                    </div>
                </div>
                <div className="row d-flex justify-content-center">
                    <div className="col-lg-4">
                        <div className="login_details">
                            <h1 className="login_">Log In</h1>
                            <p className="welcome_note">Please enter your login details below.</p>
                            <form  id="form">
                                <div className="form-group mb-4">
                                    <label className="input_label" htmlFor="uname">Email</label>
                                    <input type="text" placeholder="Email Address" name="email" value={formData.email} onChange={(e) => handleChange(e)} className="form-control input_box " />
                                    <img src={Images.Message} alt='LeftIcon' className="inputIcon" />
                                </div>
                                <div className="form-group mb-4">
                                    <label className="input_label" htmlFor="uname">Password</label>
                                    <input type={passwordShown ? "text" : "password"} placeholder="Enter Password" name="password" value={formData.password}   onChange={(e) => handleChange(e)}  className="form-control input_box " />
                                    <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                                    <span className="toggle_password_ info_icon" onClick={() => { setPasswordShown(!passwordShown) }}>
                                        <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                                    </span>
                                </div>

                                <div className="form-group rememberBox mb-4">
                                    <input type="checkbox" id="remember" name="" value="1"
                                        className="remember_me form-check-input" />
                                    <label className="input_label" htmlFor="RememberMe"> Remember Me </label>
                                </div>
                                <button className="submit_btn w-100" type="button" onClick={() => handleSubmit()}><span className="submit_text">Login</span></button>  
                                <p className="forget_password text-center  pt-3"><Link to='/admin/forgotpassword'>Forgot Password?</Link></p>
                            </form>
                        </div>
                    </div>                        
                </div>
                <div className="row text-center">
                    <p className="copyright mobileCopyright ">© Copyright {new Date().getFullYear()} all rights reserved. Aegis 24/7</p>
                </div>
            </div>
        </>
    );
};

export default Login;