
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { changePasswordAction } from "../../../redux/slices/auth";
import * as Images from "../../../utilities/images";


const ChangePassword = () => {

    const[changePassword,setChangePassword]= useState({
        oldPassword:"",
        password:"",
        confirmPass:"",
    });
    const [errorMessages, setErrorMessages] = useState({});

    const navigate = useNavigate();
    const dispatch = useDispatch();

    const [oldPasswordShown, setOldPasswordShown] = useState(false);
    const [passwordShown, setPasswordShown] = useState(false);
    const [confirmPasswordShown, setConfirmPasswordShown] = useState(false);


    const handleChange = (e) => {
        const { value, name } = e.target;
        setChangePassword({ ...changePassword, [name]: value.trim() });
        setErrorMessages({ ...errorMessages, [name]: "" });
    }

    const handleSubmit = () => {

        if (!changePassword.oldPassword) {
            toast.error("Please enter old password");
            return;
        }

        if (!changePassword.password) {
            toast.error("Please enter new password");
            return;
        }

        if (!changePassword.confirmPass) {
            toast.error("Please enter confirm password");
            return;
        }

        if (changePassword.oldPassword == changePassword.password) {
            toast.error("Old password and new password cant be same")
            return;
        }

        if (changePassword.password != changePassword.confirmPass) {
            toast.error("Password and confirm password must be same")
            return;
        }


        if (changePassword.password.length <= 7) {
            toast.error("Password must be alteast 8 character")
            return;
        }

        
        if (!/^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()_+])[A-Za-z\d.!@#$%^&*()_+]{8,}$/.test(changePassword.password)){
            toast.error("Password must be atleast 1 number , 1 capital letter and a special character");
            return
        }
            
        
        let params = {
            oldPassword : changePassword.oldPassword,
            newPassword: changePassword.password,
            confirmPassword: changePassword.confirmPass,
        }
        
        dispatch(changePasswordAction({
            ...params, cb(res) {
                if (res.status == 200) {
                    // toast.success("Password updated successfully!");
                }
                else {
                    toast.error("Something went wrong");
                }
            }
        }))
    }




    return (
        <>
            <h1>Change Password</h1>
            <div className="boxOuter">
                <form>
                    <div class="form-group mb-4">
                        <label class="input_label" for="uname">Old Password</label>
                        <input type={oldPasswordShown ? "text" : "password"} placeholder="Old Password" name="oldPassword"  value={changePassword.oldPassword} onChange={(e) => handleChange(e)} className="form-control input_box " />
                        <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />

                        <span className="toggle_password_ info_icon" onClick={() => { setOldPasswordShown(!oldPasswordShown) }}>
                            <span className={oldPasswordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>                        
                    </div>
                    <div class="form-group mb-4">
                        <label class="input_label" for="uname">New Password</label>
                        <input type={passwordShown ? "text" : "password"} placeholder="New Password" name="password" value={changePassword.password} onChange={(e) => handleChange(e)} className="form-control input_box " />
                            
                        <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                        <span className="toggle_password_ info_icon" onClick={() => { setPasswordShown(!passwordShown) }}>
                            <span className={passwordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                    <div class="form-group mb-4">
                        <label class="input_label" for="uname">Confirm Password</label>
                        <input type={confirmPasswordShown ? "text" : "password"} placeholder="Confirm Password" name="confirmPass"  value={changePassword.confirmPass} onChange={(e) => handleChange(e)} className="form-control input_box " />
                        <img src={Images.Lock} alt='LeftIcon' className="inputIcon" />
                        <span className="toggle_password_ info_icon" onClick={() => { setConfirmPasswordShown(!confirmPasswordShown) }}>
                            <span className={confirmPasswordShown ? "show-icon togglePassword" : "hide-icon togglePassword"} id=""></span>
                        </span>
                    </div>
                    <artical className="correct_password mb-2">
                    <figure>
                    <img src={Images.Correct} alt='LeftIcon' className="correctIcon" />

                        </figure>
                        <p>Must be at least 8 Characters!</p>
                    </artical>
                    <artical className="incorrect_password mb-4">
                        <figure>
                            <img src={Images.Wrong} alt='LeftIcon' className="incorrectIcon" />
                        </figure>
                        <p>Must be at least 1 Number & 1 Capital Case later!</p>
                    </artical>

                    <div className='modal_btn w-50'>
                        <button class="submit_btn w-100" type="button" onClick={()=>{handleSubmit()}}><span className="submit_text">Update</span></button>
                    </div>
                </form>
            </div>
        </>
    )

}

export default ChangePassword;